
body{
  background-color: rgb(28,30,43);
  font-family: 'Poppins', sans-serif;
  color: #000;
}
h1,h2,h3,h4,h5,h6,p,span,a{
  font-family: 'Poppins', sans-serif;
}
table, tr, td , th{
 color:#fff;
 font-family: 'Poppins', sans-serif;
 font-size: 13px;
}
p{
  margin-bottom:0px;
}
/* =====================================Header===================================================================================================================================================================================================================================== */
.Header_Main_whole_wrapper {
  background-color: #000000;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:15px 46px;
  width:100%;
  position: fixed;
  left:0;
  z-index: 9999999 !important;
}
.Header_logo_wrapper img{
 width:6%;
}
.Header_Right_side_content{
  display: flex;
  align-items: center;
  justify-content: end;

}
.Header_Right_side_content div{
  padding-right:15px;
}
.Header_Right_side_content div p{
  margin-bottom:0px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  text-align: right !important;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #BCC4DF;
}
.Header_Right_side_content div h5{
  margin-bottom:0px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
}
.Heade_Power_button 
{
  background-color: #282F42;
  border-radius:100px ;
}
/* =========================================End======================================================================================================================================================================================================================================= */
/* ================================DashboardPage============================================================================================================================================================================================================================================= */

.Dashboard_page_Whole_content_wrapper_main{
display: flex;
height:100%;
}
.Dashboard_page_Map_wrapper_Main_whole img {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}
.Dashboard_page_side_bar{
  width:100%; 
  background-color: #282F42;
  color: #BCC4DF !important;
  text-decoration: none !important;
}
.Dashboard_page_side_bar_close{
  background-color: #282F42;
  color: #BCC4DF !important;
  width:100%;
  list-style: none;
  padding-left:0px !important;
}
.Dashboard_page_side_bar_close a li span img{
  width:65% !important;
}
.Dashboard_page_side_bar {
  list-style: none;
  padding-left:0px !important;
}
.Dashboard_side_bar_links{
  text-decoration: none !important;
  color:#BCC4DF;
}
.Dashboard_side_bar_links:hover{
  color:white !important; 
}
.Dashboard_page_right_side{
  width:85%;
  padding:65px;
  margin-top:3rem;
}
.Dashboard_SideBar_icon {
  /* filter: invert(100%) sepia(30%) saturate(4430%) hue-rotate(177deg) brightness(95%) contrast(83%) !important; */
  width: 25%;
  margin-right:5px;
}
.Dashboard_SideBar_icon_one{
  width:60%;
}
.Dashboard_page_side_bar li {
  padding: 30px;
  padding-left: 46px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start !important;
}
.Dashboard_page_side_bar_close li{
  padding: 20px;
  padding-top:1rem;
  padding-bottom:1rem;
  cursor: pointer;
  text-align: center;
}
.Dashboard_page_side_bar_close li p{
  display: none;
}
.Dashboard_page_side_bar_close li img{
  width:55%;
}
.Dashboard_side_bar_wrapper_main_long{
  width:15%;
  background-color: #282F42;
  height:90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 11%;
}
.margin_left{
  margin-left:15%;
}
.margin_left_close{
  margin-left:6%;
  width:94% !important;
}
.Dashboard_side_bar_wrapper_main_short{
  width:6%;
  background-color: #282F42;
  height:90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 11%;
  left: 0%;
}
.SideBar_active {
  background-color: rgb(28,30,43);
  color: white;
  font-weight: 700;
  letter-spacing: 0px;
  font-size: 18px;
}
.Dashboard_SideBar_icon_one{
  width:30px;
  height:30px;
}
.Dashboard_page_rightSide_content_heading h4,.Dashboard_page_rightSide_content_heading span{
font-family: 'Poppins',sans-serif;
font-style: normal;
font-weight: 600;
font-size: 15px;
line-height: 18px;
color: white;
}
.Dashboard_page_rightSide_content_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:20px;
}
.Dashboard_page_rightSide_content_heading_span{
  padding-right: 20px;
  padding-top:0px;
}
.switchcase-active {
  color: #BCC4DF !important;
  font-weight: 300 !important;
}
.menu-bar-btn{
  background-color: transparent;
  color:white;
  border:none;
  text-align: center;
  margin:20px;
}
.Dasboard_page_individual_fleet_box_wrapper {
  background-color: #282F42;
  color: white;
  padding: 23px;
  border-radius: 10px;
  margin: 23px;
  width:310px;
  cursor: pointer;
}
.Dasboard_page_individual_robot_box_wrapper{
  background-color: #282F42;
  color: white;
  padding: 23px;
  border-radius: 10px;
  margin: 23px;
  width:310px;
  cursor: pointer;
}
.Dashboard_page_fleets_list_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.Dashboard_page_fleets_list_wrapper_two {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.Dasboard_page_individual_fleet_box_inner_div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:12px;
}
.Dasboard_page_individual_fleet_box_wrapper h4 {
  text-align: center;
  font-size: 17px;
  position: relative;
  margin-bottom: 20px;
  padding-bottom:15px;
}
.Dashboard_page_individual_fleet_Heading_Green_h4::after {
  content: '';
  background-color: #88F976;
  width: 20%;
  height: 3px;
  position: absolute;
  left: 40%;
  top: 31px;
}
.Dashboard_page_individual_fleet_Heading_red_h4::after {
  content: '';
  background-color: #FF5757;
  width: 20%;
  height: 3px;
  position: absolute;
  left: 40%;
  top: 31px;
}
.Dasboard_page_individual_fleet_box_inner_div p{
  color:#BCC4DF;
}
.Dasboard_page_individual_fleet_box_inner_div p:last-child{
  color:#BCC4DF;
  font-weight: 600;
}
.Dashboard_page_robotStatusClass{
font-size: 13px;
}
.Dasboard_page_individual_robot_h4{
  font-size: 15px;
  margin-bottom:2px;
}
.owl-theme .owl-dots{
  display: none !important;
}
.Dashboard_page_fleets_list_wrapper .owl-nav.disabled {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  top: 20%;
  position: absolute;
}
.Dashboard_page_fleets_list_wrapper button.owl-prev,.Dashboard_page_fleets_list_wrapper_two button.owl-prev{
  margin-left: -2rem !important;
  z-index: 999999 !important;
}
.Dashboard_page_fleets_list_wrapper button.owl-next,.Dashboard_page_fleets_list_wrapper_two button.owl-next{
  margin-right: -2rem !important;
}
 button.owl-prev:hover{
  background: transparent !important;
}
button.owl-next:hover{
  background: transparent !important;
}
.Dashboard_page_fleets_list_wrapper_two .owl-nav.disabled {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  top: -4%;
  position: absolute;
}
.Dashboard_page_fleets_list_wrapper span,.Dashboard_page_fleets_list_wrapper_two span {
  font-size: 100px;
  color: #969393;
  font-weight: 200;
}
.Dashboard_page_fleets_list_wrapper span:hover,.Dashboard_page_fleets_list_wrapper_two span:hover {
background-color: transparent;
}

.red-dot{
  background-color: #FF6767;
  border-radius: 100px;
  width:15px;
  height:15px;
}
.green-dot{
  background-color: #88F976;
  border-radius: 100px;
  width:15px;
  height:15px;
  animation-name: dotanimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.yellow-dot{
  background-color: #FFB800;
  border-radius: 100px;
  width:15px;
  height:15px;
  animation-name: dotanimation;
  animation-duration:1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes dotanimation {
  0%   {opacity:0}
  50%   {opacity: 0.5;}
  100%   {opacity: 1;}
}
.Dashboard_page_robot_div_icons{
  display: flex;
  justify-content: space-around;
  margin-top:15px;
  font-size: 14px;
  align-items: center;
}
.Dashboard_page_robot_div_icons img{
  margin-right:5px;
  margin-left:5px;
}
.Dashboard_page_individual_robot_robotstatus_div {
  text-align: center;
  margin-top: 11px;
  font-size: 13px;
}
.Dashboard_page_Robot_Card_heading{
  font-family: 'Poppins',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: white;
}
.Dashboard_page_Robot_Card_heading span{
  font-size: 13px;
}
.owl-carousel .owl-item img{
  width:10% !important;
}
/* ======================================End========================================================================================================================================================================================================================================================== */
/* =====================FleetPage========================================================================================================================================================================================================================================================== */
.Fleet_page_Whole_content_wrapper_main{
  display: flex;
  height:100%;
}
.Dashboard_page_right_side_fleetpage {
  color: white;
  width: 100%;
  margin-top: 123px;
}
.Dashboard_page_right_side_fleetpage h4 {
  padding-left: 52px;
  font-size: 17px;
  padding-bottom: 13px;
  padding-top: 9px;
}
.Fleet_page_table_Main_whole{
  width:100%;
}
.Fleet_page_table_Main_whole thead{
  background-color: #3D4665;
}
.Fleet_page_table_Main_whole th{
color: #BCC4DF;
font-weight: 600;
font-size: 13px;
line-height: 19px;
}
.Fleet_page_table_Main_whole td, .Fleet_page_table_Main_whole th{
  padding: 12px;
  padding-left: 29px;
  padding-right: 25px;
}
.Fleet_page_table_Main_whole td{
font-weight: 400;
font-size: 12px;
line-height: 15px;
color: #FFFFFF;
border-bottom: 1px solid #BCC4DF;
}
.FleetPage_table_action_td{
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left:0px !important;
}
.FleetPage_table_action_td img{
margin-right:10px;
margin-left:10px;
}
.FleetPage_table_status_td_img{
  width:20px;
  height:20px;
}
.FleetPage_plusIcon{
  position:fixed !important;
  bottom:20px  !important;
  right:20px !important;
  color: #000 !important; 
  background-color: #BCC4DF;
  border-radius: 100%;
  padding: 13px;
}
.css-118zhtq-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab{
background-color: #BCC4DF !important; 
}
.css-ohncnb-MuiSpeedDialIcon-root .MuiSpeedDialIcon-icon{
  color:black;
}
/* ==========================End===================================================================================================================================================================================================================================================== */
/* ====================Forgot Password Page=========================================================================================================================================================================================================================================== */
.Cart_page_otp_input input {
  width: 40px !important;
  height: 40px !important;
  border: 1px solid #BCC4DF;
  background-color: rgb(28,30,43) !important;
  color: #BCC4DF !important;
}
.Cart_page_otp_input{
  justify-content: center !important;
}
.Loginpage-main-form-inner-div_otp{
  margin:auto;
  width:90%;
  border-radius: 5px;
  margin-top: 15px;
}
/* =============================End======================================================================================================================================================================================================================= */
/* =============================LoginPage======================================================================================================================================================================================================================= */
.Login-page-form {
  width: 35%;
  /* margin: auto; */
  background: rgb(40,47,66);
  /* box-shadow:0px 4px 33px 12px rgb(40,47,66); */
  padding: 25px;
  padding-top: 37px;
  border-radius: 8px;
  text-align: center;
  margin-top: 2rem;
}
.Login_page-wholeMain-wrapper{
  /* background-image: url('../public/assets/images/Ottonomy_Logo_With\ TM_Version\ 2-02\ 3.png') ,url('../public/assets/images/Ottonomy_Logo_With TM_Version 2-02 2two.png') ;
  background-repeat: no-repeat ,no-repeat;
  background-position: top left , bottom right; */
}
.Login_page-wholeMain-wrapper:before{
  content:'';
  background-image: url('../public/assets/images/Ottonomy_Logo_With\ TM_Version\ 2-02\ 3.png');
  background-repeat: no-repeat;
  background-size: 200px;
  height: 200px;
  left: 0;
  opacity: 1;
  position: absolute;
  width: 200px;
  top: 20px;
}
.Login_page-wholeMain-wrapper:after{
  background-image: url('../public/assets/images/Ottonomy_Logo_With TM_Version 2-02 2two.png');
  background-repeat: no-repeat;
  background-size: 200px;
  content: "";
  height: 200px;
  right: 0;
  opacity: 1;
  position: absolute;
  width: 200px;
  bottom: 20px;
}
input:focus{
  background-color: transparent !important;
}
/* input:-webkit-autofill{
  background-color: transparent !important;
  color:transparent !important;
} */
.LoginPage-Main-wrapper {
  height:100vh;
  align-items: center;
  align-self: center;
  vertical-align: middle;
  /* background-color: white; */
  display: flex;
  width:100%;
  flex-direction: column;
  justify-content: center;
}
.Login-page-form .css-1a1fmpi-MuiInputBase-root-MuiInput-root{
  width:95%;
  margin:auto;
}
.Login-page-form-logo-image {
  width: 8%;
}
.Loginpage-main-form-inner-div{
background-color: rgb(28,30,43) !important;
margin:auto;
width:90%;
border-radius: 5px;
}
div#demo-simple-select {
  /* border: none !important; */
  border-bottom: 0.5px solid #BCC4DF !important;
  padding: 7px;
  color:#BCC4DF;
}
.AddFleet_form_field_wrapper>div>div svg{
color:rgb(151, 159, 184);
}
.Loginpage-main-form-inner-div >div >div::before{
  border-bottom: 1px solid rgb(151, 159, 184);
}
.Loginpage-main-form-inner-div >div >div::after{
  border-bottom: 1px solid rgb(151, 159, 184);
}
.Login_page_input_wholeLabel_wrapper {
  margin-top: 2rem;
}
.Login_page_input_wholeLabel_wrapper label{
  font-family: 'Poppins',sans-serif;
}
.Login-form-p {
  margin: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  color: rgb(1,115,212);
  font-weight: 600;
  margin-top:10px  !important;
  margin-bottom:0px !important; 
}
.Login-page-form h6 {
  margin: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 400;
  font-size: 17px;
  margin-top: 0px !important;
}
.Loginpage\=-main-form {
  margin: 24px;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 13px;
  margin-top: 46px;
}
.Loginpage\=-main-form input{
  font-size: 15px !important;
  font-weight: 600 !important;
  color:rgb(189,196,224) !important;
  letter-spacing: 0px !important;
  padding:12px;
  background: none !important;
  font-family: 'Poppins', sans-serif;
}

.Login-page-form .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 15.9px;
  font-family: 'Poppins', sans-serif;
  color:rgb(189,196,224);
  font-weight: 500;
}

.Loginpage-btn-wrapper-btn {
  color: black !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 15px !important;
  background-color: rgb(189,196,224) !important;
  padding: 13px 70px!important;
  border-radius: 50px!important;
  /* width: 21% !important; */
  border: none;
  font-weight: 700;

}
.Login-page-form .css-1d1r5q-MuiFormHelperText-root.Mui-error{
font-weight: 400;
font-size: 10px;
padding-left:39px;
}
.Login-page-form .css-dmmspl-MuiFormGroup-root{
  padding-left: 15px
}
img.Logout-image {
  width: 100%;
  margin-left: 2px;
}
.Login-page-remember-checkbox{
width:25px;
height:25px;
}
.Login-page-remember-checkbox-span{
  margin:10px;
  color:rgb(189,196,224);
}
.Loginpage\=-main-form input:-webkit-autofill{
  background: none !important;
}
.Loginpage\=-main-form  input:-webkit-autofill,
.Loginpage\=-main-form  input:-webkit-autofill:hover, 
.Loginpage\=-main-form input:-webkit-autofill:focus,
.Loginpage\=-main-form  input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px rgb(28,30,43) inset !important;
    -webkit-text-fill-color:rgb(189,196,224) !important;
  }
  .AddFleet_form_field_wrapper input:-webkit-autofill{
    background: none !important;
  }
  .AddFleet_form_field_wrapper input:-webkit-autofill,.AddFleet_form_field_wrapper input,
  .AddFleet_form_field_wrapper input:-webkit-autofill:hover, 
  .AddFleet_form_field_wrapper input:-webkit-autofill:focus,
  .AddFleet_form_field_wrapper input:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px rgb(28,30,43) inset !important;
      -webkit-text-fill-color:rgb(189,196,224) !important;
    } 

.Loginpage-btn-wrapper {
  margin-top: 35px;
}
.Login_page_forgot_password_div_wrapper p{
  text-align:end;
  color:rgb(189,196,224);
  width:95%;
  font-size: 13px;
  margin-top: 17px;
}
/* =================================End======================================================================================================================================================================================================================================================================= */

/* =====================================IndividualFleet=================================================================================================================================================================================================================================================================== */
.FleetView-Page-Top-Content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:26px 70px;
}
.FleetView_page_Play_Pause_wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 10%;
}
.FleetView_page_Play_Pause_wrapper img {
  width: 60% !important;
}
.FleetView_page_Heading_fleetName{
  font-family:  'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 18px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #FFFFFF;
}
.FleetView_page_haeding_tag{
  font-family:  'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 17px;
  font-feature-settings: 'tnum' on, 'lnum' on;  
  color: #FFFFFF; 
}
.FleetView_page_Play_Pause_wrapper p{
  font-family:  'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11.7px;
  line-height: 12px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  margin-left: 5px;
  color: white;
  margin-top: 4px;
}
.FleetView_page_OrderList_content{
  margin-right:20px;
}
.FleetView_page_OrderList_pause_wrapper{
  text-align: right;
  display: flex;
  justify-content: right;
  align-items: center;
}
.FleetView_page_Image_Button_wrapper_wholeMain{
  position: relative;
}
.FleetView_page_Popper_content_one_wrapper{
padding:30px;
}

.FleetView_page_Popper_content_one_wrapper_inner-one{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}
.FleetView_page_Popper_content_one_wrapper_inner_two{
margin-bottom: 2rem;
margin-top: 2rem;
}
.FleetView_page_Popper_content_two{
  margin:23px;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root{
  font-size:11.9px !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
  color:rgb(50, 234, 9) !important;
  font-size:11.9px !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active{
  color:rgb(166, 241, 149) !important;
  font-size:11.9px !important;
}
.css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel{
  font-size:11.9px;
}
.css-zpcwqm-MuiStepConnector-root{
  top:7px !important;
}
.FleetView_page_Popper_content_two_wrapper{
  padding:30px;
  padding-left:24px;
  padding-right:24px;
}


.FleetView_page_Popper_content_one_wrapper_inner-one h6, .FleetView_page_Popper_content_one_wrapper_inner-one p{ 
  margin-bottom:0px !important;
}
.FleetView_page_Popper_content_one_wrapper_inner-one div{
margin:15px;
}
.Individual_fleet_page_map_wrapper {
  margin: 2rem;
  margin-top:0px;
}
.FleetView_page_OrderList_content button{
    color: black !important;
    text-transform: capitalize !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 15px !important;
    background-color: rgb(189,196,224) !important;
    padding: 10px 50px!important;
    border-radius: 50px!important;
    /* width: 21% !important; */
    border: none;
    font-weight: 700;
}
/* =====SecondSub=============== */

.navContainer {
  display: flex;
  justify-content: space-between;
  background-color: #dfdfdf;
  margin-bottom: 40px;
}
.uppr {
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 15px;
  background: #D5D2D2;
  padding: 10px;
  box-shadow: none;
}
.uppr h3 {
  font-weight: 400;
  margin-bottom: 0px;
  border-right: 1px solid black;
  padding: 5px;
  font-size: 13px !important;
  line-height: 15px !important;
  padding-left: 13px;
}
.FleetView_page_Image_Button_wrapper_wholeMain {
  position: relative;
}
.btn-grp {
  right: 0;
  /* display: flex; */
  display: none;
  flex-direction: column;
  position: absolute;
  z-index: 999999;
  top: 6rem;
  right: 6rem;
}
.btn-top {
  border-radius: 50px !important;
  background-color: #BCC4DF!important;
  border: #ccc !important;
  padding: 8px 6px !important;
  align-self: center !important;
  margin-bottom: 16px !important;
  width: 165px !important;
  color: black !important;
  text-transform: capitalize !important;
}
.fl-map {
  width: 95%;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top:-6rem;
}
.t-des {
  float: left;
  background: #292F43;
  padding: 4px 8px;
  display: flex;
  text-align: center;
  margin: 30px 80px;
  border-radius: 20px;
  border-left: 8px solid #000;
  /* position: absolute;
  bottom: 40px; */
  z-index: 9999999999;
  /* box-shadow: 0 2px 5px #ccc; */
  /* left:22px; */
  width:auto;
}
#full-width{

}
.map-icons {
  position: fixed;
  display: none;
  flex-direction: column;
  padding-bottom: 2px;
  right: 80px;
  bottom: 30px;
  z-index: 2;
}
.uppr h3 span {
  font-weight: 600;
}
h3 span {
  margin-left: 14px;
  padding-right: 20px;
}
.dashboard-left-side-div-one-wrapper h3 span{
  margin-left: 0px;
  padding-right: 0px;
}
table td .red-alert {
  color: #FF6767;
}
table td .orange-alert {
  color: #FFB800;
}
table td .yellow-alert {
  color: #FFDD2D;
}
table td .green-alert {
  color: #88F976;
}
.fill-square {
  border-radius: 6px;
  padding: 4px;
  color: rgba(0, 0, 0, 0.567);
  background-color: #BCC4DF;
  margin-bottom: 14px;
}
.fill-circle {
  border-radius: 12px;
  padding: 4px;
  background-color: #BCC4DF;
  color: rgba(0, 0, 0, 0.566);
  margin-bottom: 14px;
}
table td span {
  font-family:  'Poppins', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 44px;
  padding: 0 14px;
}

/* ============================================End====================================================================================================================================================================================================================================================================== */

/* ============================================Popup Card====================================================================================================================================================================================================================================================================== */
.popupcard-box {
  width:270px;
  float: left;
  background: rgb(255, 255, 255);
  padding:32px;
  border-radius: 28px !important;
  text-align: center;
   margin: 0 15px;
   padding-top: 24px; 
}
.popupcard-box h2 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  /* // margin-bottom: 20px; */
}
.popupcard-box h2:before {
  position: absolute;
  width: 26px;
  content: ' ';
  height: 2px;
  background: #FAA659;
  bottom: -8px;
  left: 45%;
  border-radius: 4px;
}
.popupcard-box h2.orange-alert:before {
  background: var(--orange-alert);
}
.popupcard-box h2.yellow-alert:before {
  background: var(--yellow-alert);
}
.popupcard-box h2.green-alert:before {
  background: var(--green-alert);
}
.popupcard-box p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 0;
  font-family:  'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #444343;
}
.popupcard-box p span {
  font-weight: 500;
  font-family:  'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #444343;
}

/* ============================================End====================================================================================================================================================================================================================================================================== */
/* ======================================Map========================================================================================================================================================================================================================================================================================= */
.Dashboard_page_Map_wrapper_Main_whole h4{
  color:white;
}

/* =========================================End=================================================================================================================================================================================================================================================================================================== */



/* ====================RobotIndividual=================================================================================================================================================================================================================================================================================================================== */
.dashboard-left-side-div-wrapper-Main{
  padding:30px;
}
.dashboard-left-side-div-one-wrapper h3 {
  color: white;
  font-size: 17px;
  margin-bottom: 0px;
  text-align: start;
}
.dashboard-left-side-div-one-inner-div {
  text-align: end;
  margin-top: -8px;
  padding-right:10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 36px;
  padding-right: 0px;
}
.dashboard-left-side-div-one-wrapper span {
  color: rgb(113, 207, 98);
  font-size: 13px;
}
.dashboard-left-side-div-one-wrapper-battery-div p {
  margin-bottom: 0px;
  color: white;
  font-size: 8px;
  margin: 10px;
  margin-top: 0px;
  margin-bottom:0px;
}
.dashboard_left_side_available_div {
  text-align: left;
}
.dashboard_left_side_available_div h4 {
  font-size: 10px;
  color: white !important;
}
.RobotActions_content_wrapper_div_orange{

}
.dashboard-left-side-div-one-wrapper-battery-div {
  display: inline-block;
  text-align: center;
}
.dashboard-left-side-div-two-wrapper-h5 {
  color: white;
  font-weight: 300;
  font-size: 15px;
}
.dashboard-left-side-div-three-wrapper {
  margin-top: 16px;
}
.dashboard-left-side-div-four-wrapper {
  margin-top: 16px;
}

.dashboard-left-side-div-two-Map-Container-one
{
  background-color: #282F42;
  height: 170px;
  overflow: scroll;
}
.dashboard-left-side-div-two-Map-Container-two
{
  background-color: #282F42;
  /* height: 170px; */
   /* border:3px solid rgb(251,98,98) !important; */
}
.dashboard-left-side-div-two-Map-Container-two h4 {
  color: white;
  font-size: 17px;
  text-align: center;
  padding: 19px 13px;
}
.dashboard-left-side-div-two-Map-Container-one::-webkit-scrollbar{
background-color:#282F42;
color:#fff !important;
width:10px;
}
.dashboard-left-side-div-two-Map-Container-one::-webkit-scrollbar-track{
  color:#ffffff !important;
} 
.dashboard-left-side-div-two-Map-Container-one::-webkit-scrollbar-thumb {
  background-color:#7d7d7d !important;

}
.dashboard-left-side-div-two-robot-button-Container.d-flex-center{
  background-color: #282F42;
  flex-wrap: wrap !important;
}
.dashboard-left-side-div-two-robot-button-Container button {
  border-radius: 34px;
  padding: 5px 24px;
  background-color: rgb(189,196,224);
  border: none !important;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 17px;
  margin-left: 10px;
  margin-right: 10px;
}
.dashboard-right-side-div-wrapper-Main{
  background-color: #282F42;
  height:auto;
}
.Dashboard-Page-bottom-div-Main-wrapper{
  width:100%;
  background-color: black;
  position: fixed;
  bottom:0;
  padding: 19px  25px;
  z-index:999999999 !important;
}
.DashBoard-page-Main-Whole-wrapper{
  padding-left:0px !important;
}
.Dashboard-Page-bottom-div-Main-wrapper-content-p {
  color: white !important;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-left:-6px;
}
.Dashboard-Page-bottom-div-Main-wrapper-content-span{
color: rgb(189,196,224);
}
.Dashboard-Page-bottom-div-Main-wrapper.d-flex-center button {
  background-color: #282F42;
  border:2px solid #a2a2a2;
  border-radius: 30px;
  padding: 4px 22px;
  font-size: 12px;
  color:white;
  font-weight: 200;
}
.Cancel-order-Modal-Mainwrapper p {
  margin-bottom: 20px;
  padding-left: 14px;
  color: white;
  text-align: center;
}
.Modal-button-Main-btn{
  border-radius: 30px !important;
  border:1px solid rgb(205,4,2) !important;
  color:rgb(205,4,2) !important;
  padding:6px 41px !important;
  font-weight: 600 !important;
  margin: 10px !important;
  margin-top: 0px !important;
  }
.yes-red-active{
    background: linear-gradient(to right, #F4463D, rgb(205,4,2)) !important;
    color:#ffffff !important;
}
.Modal-button-Main-wrapper {
  margin-top: 22px;
  text-align: center;
}
.Dashboard-page-teleop-button-disabled{
  background-color: #78797a !important; 
}
.Dashboard-Page-Power-button{
  background-color: #282F42;
  border-radius: 30px;
   width:35px !important;
   height:35px !important;
   padding:5px;
}
.col-md-9.dashboard-right-side-div-wrapper-Main {
  height: 90vh;
}
.Stop-button-dashhboard-page{
  background-color: rgb(228 51 41) !important;
  color:white !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  border:none !important; 
  padding: 6px 33px !important;
}
.play-icon{
  cursor:pointer;
}
.Dashboard-page-teleop-button{
  /* border:2px solid rgb(220,53,69) !important;  */
}
.Error-state-teleop-ack-button{
  background-color: rgb(220,53,69) !important;
  color:white !important; 
  font-size: 10px;
  position: absolute;
  right: 0rem;
  top: -10px;
  border:none !important;
}
.dashboard_page_Map_heading_wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:12px;
  margin-top:12px;
}
.Dashboard_page_Map_wrapper_Main_whole {
  padding: 2rem;
  padding-top: 1rem;
  padding-right:0px;
  padding-left:0px;
}
.leaflet-marker-icon{
  position: relative;
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border-radius: 100%;
}
@keyframes example {
  0%   {transition: 1s ease-in-out;box-shadow: 0px 0px 0px 5px rgb(253, 243, 45); }
  50%   {transition: 1s ease-in-out;box-shadow: 0px 0px 0px 5px rgb(253, 243, 45); }
  100% {border:none;transition: 1s ease-in-out}
}
.col-md-9.dashboard-right-side-div-wrapper-Main h2 {
  font-size: 22px;
  color: white;
  padding: 50px;
  padding-bottom: 0px;
  font-size: 17px;
}
.RobotActions-content-wrapper-div p{
  color: #ffffff;
}
.RobotActions-content-wrapper-div {
  text-align: center;
}
.RobotActions_content_wrapper_div_orange{
  border:2px solid #FAA659;
}
.RobotActions_content_wrapper_div_orange p span{
  color:#FAA659;
}
.No-robots-available{
  font-size:13px;
  color:white;
  }
  .RobotActions-content-wrapper-div {
    color: #ffffff !important;
    padding: 19px;
  }
  .RobotActions-content-wrapper-div h3{
    font-size:15px;
    margin-bottom:10px;
    text-align: left;
  }
  .RobotActions-content-wrapper-div p{
    font-size:13px;
    margin-bottom:0px;
    text-align: left;
  }
  .RobotActions-button-wrapper {
    margin-top: 20px;
  }
  .RobotActions-button-wrapper button{
    border:none;
    color:white !important;
    border-radius:5px;
    font-size:13px;
    padding:5px 15px;
    margin:5px;
  }
  .RobotActions-Start-btn{
  background-color: rgb(40,167,69);
  }
  .RobotActions-cancel-btn{
  background-color: rgb(220,53,69);
  }
/* ===========================End================================================================================================================================================================================================================================================================================================================================== */
/* ===========================Add fleet Page================================================================================================================================================================================================================================================================================================================================== */

.AddFleet_form_field_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 0rem;
}
.AddFleet_form_field_wrapper input{
  color:white;
}
.AddFleet_form_field_wrapper input::placeholder{
  color:#BCC4DF;
}
.AddFleet_form_field_wrapper>div>div::before{
content:'';
border-bottom:0.5px solid #BCC4DF !important;
}
.AddFleet_page_content form{
  width:50%;
}
.AddFleet_form_field_wrapper>div>div {
  width:100%;
}
.AddFleet_page_content {
  padding-left: 52px;
  margin-top:4rem !important;
}
.AddFleet_page_save_button_wrapper{
  text-align: right !important;
  display: flex;
  justify-content: end;
  align-items: center;
}
.AddFleet_page_save_button_wrapper button{
  background: #BCC4DF;
  border: 1px solid #BCC4DF;
  border-radius: 50px;
  padding: 3px 33px;
  font-size: 15px;
}
.AddOrder_text_response_wrapper p{
  background-color: #282F42;
  padding: 10px 30px;
  color: #BCC4DF;
  margin: 19px;
  border-radius: 24px;
  font-size: 13px;
}
.cursor_pointer{
  cursor: pointer;
}
p.Modal-wrapper-p {
  color: white !important;
  font-weight: 600;
  margin-bottom: 20px;
}
/* ===========================End==================================================================================================================================================================================================================================================================================================================================  */
/* ==============OrderList Page================================================================================================================================================================================================================================================================================================================================================== */
button.OrderLIst_page_confirm_button {
  font-size: 12px;
  padding: 7px 10px;
  background-color: #3D4665;
  color: white;
  border: none;
}
/* ==================End================================================================================================================================================================================================================================================================================================================================================================ */
/* ===========================IndividualUserPage==================================================================================================================================================================================================================================================================================================================================  */
.Individual_User_page_row_wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:75%;
}
.Individual_userCheck_box_input svg{
  color: #BCC4DF;
  background-color:#3D4665;
  font-family: 'Poppins',sans-serif !important;
}
.AddFleet_page_save_button_wrapper.Individual_user_page {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
}
.Individual_UserPage_Input_checkbox_wrapper span{
  font-size:13px !important;
}
/* ===========================End==================================================================================================================================================================================================================================================================================================================================  */

/* ===========================Delete Modal==================================================================================================================================================================================================================================================================================================================================  */
.Delete_modal_close_btn{ 
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
}
.Modal_content_button_wrapper{
  text-align: center;
}
.Text_Field_Input_class label{
color:white;
}

/* ===========================End==================================================================================================================================================================================================================================================================================================================================  */

/* ==========================Responsive================================================================================================================================================================================================================================================================================================================= */
@media only screen and (max-width: 1192px) {
  .Dashboard_side_bar_wrapper_main_long {
    width: 20%;
    top: 5%;
  }
  .margin_left {
    margin-left: 22%;
}
.SideBar_active{
font-size:14px;  
}
}
@media only screen and (max-width: 1024px) {
  .Dashboard_page_right_side_fleetpage{
  overflow-x: scroll;
}

img.Dashboard_SideBar_icon {
  width: 30px !important;
  height: 30px !important;
}
.Dashboard_side_bar_P{
  font-size: 11px;
}
.margin_left {
  margin-left: 20%;
}
button.menu-bar-btn {
  display: none;
}
.Dashboard_page_side_bar li {
  padding-left: 25px;
}
.Header_logo_wrapper img {
  width: 14%;
}
.Header_Right_side_content div h5,.Header_Right_side_content div p {
  font-size:9px;
}
.Dashboard_side_bar_wrapper_main_long {
  width: 20%;
  top: 8%;
}
.Dashboard_page_rightSide_content_heading{
  display: block;
}
.Dashboard_page_right_side{

  padding-right: 50px;
  padding-left: 24px;
}
.Map_view_select_box{
  justify-content: end !important;
}
.FleetPage_table_action_td img {
margin-bottom:13px;
}
.FleetView_page_haeding_tag {
  font-size:13px;
}
.FleetView_page_Heading_fleetName{
  font-size:13px;
}
.FleetView_page_OrderList_content button {
  color: black !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 13px !important;
  background-color: rgb(189,196,224) !important;
  padding: 6px 39px!important;
  border-radius: 50px!important;
  border: none;
  font-weight: 700;
}
.FleetView_page_Play_Pause_wrapper p{
  font-size: 9.7px;
}
.AddFleet_page_content form{
  width:80%;
}
.Login-page-form-logo-image {
  width: 16%;
}
.Login-page-form {
    width: 70%;
}
}
@media only screen and (max-width: 767px) {

.Header_logo_wrapper img {
  width: 40%;
}
.Dashboard_side_bar_P{
  display:none;
}
.AddFleet_page_content form {
  width: 80%;
}
.AddFleet_page_content {
  padding-left: 17px;
  margin-top: 1rem !important;
}
.AddFleet_form_field_wrapper {
  display: block;
}
.Dasboard_page_individual_fleet_box_inner_div p:last-child{
  font-size:8px;
}
.FleetView_page_haeding_tag {
  font-size: 10px;
}
.FleetView_page_Heading_fleetName {
  font-size: 10px;
}
.FleetView_page_OrderList_pause_wrapper {
  justify-content: space-between;
  padding-right: 20px;
}

.FleetView_page_OrderList_content button {
  color: black !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 10px !important;
  background-color: rgb(189,196,224) !important;
  padding: 1px 23px!important;
  border-radius: 50px!important;
  border: none;
  font-weight: 700;
}
.FleetView-Page-Top-Content-wrapper{
  justify-content: space-between;
  padding: 0px 31px;
  padding-right: 61px;
  display: block;
}
.Dasboard_page_individual_fleet_box_inner_div p {
  color: #BCC4DF;
  font-size: 10px;
}
.Dasboard_page_individual_fleet_box_wrapper h4 {
  font-size: 15px;
}
.Dasboard_page_individual_robot_h4 {
  font-size: 13px;
}
.Login-page-form-logo-image {
  width: 30%;
}
.Login-page-form {
  width: 80%;
}
.Login_page-wholeMain-wrapper:before{
  background-size: 100px;
}
.Login_page-wholeMain-wrapper:after{
  background-size: 100px;
}
}
/* ==========================End================================================================================================================================================================================================================================================================================================================= */

.Toastify__toast-container--top-right{
  z-index:9999999999999999999999999 !important;
  text-transform: capitalize !important;
  font-weight: 600;
}


/* =============Slider===================================================== */


/* html {
  box-sizing: border-box;
  font-size: 10px;

}
@media (max-width: 425px) {
html {
  font-size: 8px;
  }
}
body {
  font-size: 1.5rem;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: inherit;
}

ul {
  list-style-type: none;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.carousel__wrap {
  margin-top: 10%;
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
}

.carousel__inner {
  height: 40rem;
  position: relative;
  width: calc(30rem * 3);
}

.carousel__container {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel__slide-list {
  height: 100%;
  left: 50%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  width: calc((5 + 0.5) * 30rem * 2);
}

.carousel__slide-item {
  display: inline-block;
  height: 30rem;
  margin: 0;
  padding: 1rem;
  position: absolute;
  transition: all 0.3s;
  width: 30rem;
}
.carousel__slide-item-img-link img {
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
  width: 100%;
}
.carousel__slide-item-img-link::after {
  align-items: center;
  background: rgba(black, 0.5);
  color: white;
  content: "read more";
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  width: 100%;
}
.carousel__slide-item-img-link::after {
  opacity: 1;
}
.carousel__slide-item-img-link:hover {
    opacity: 1;
  }
  .carousel__slide-item-img-link img{
 
      transform: scale(1.3);
  }
.carousel__slide-item-img-link {
  cursor: zoom-in;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%; 
  }


.carousel-slide-item__body {
  bottom: -2.5rem;
  height: 10%;
  position: absolute;


}
h4 {
  margin: 0.7rem 0 0;
  text-transform: uppercase;
}

p {
  font-size: 1.2rem;
  line-height: 1.3;
  margin: 0.7rem 0 0;
}
.carousel__btn {
  align-items: center;
  background: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);


}
.carousel__btn--prev {
  left: -10rem;
}

.carousel__btn--next {
  right: -10rem;
}
.carousel__btn-arrow {
  border: solid black;
  border-width: 0 0.4rem 0.4rem 0;
  height: 6rem;
  padding: 3px;
  width: 6rem;
  z-index: 10;
}

.carousel__btn-arrow--left {
  transform: rotate(135deg);
}

.carousel__btn-arrow--right {
  transform: rotate(-45deg);
}
.carousel__dots {
  display: inline-block;
  left: 50%;
  margin-top: 2rem;
  position: absolute;
  transform: translateX(-50%);


}
.dot {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 2rem;
  margin: 0 0.3rem;
  outline: none;
  transform: scale(0.5);
  width: 2rem;
}
.dot.active {
  background: black;
} */
/* =====================End========================================================= */

@keyframes example {
  0%   {transition: 1s ease-in-out;box-shadow: 0px 0px 0px 5px rgb(253, 243, 45);   border-radius: 100%;}
  50%   {transition: 1s ease-in-out;box-shadow: 0px 0px 0px 5px rgb(253, 243, 45);   border-radius: 100%;}
  100% {border:none;transition: 1s ease-in-out;  border-radius: 100%;}
}
.gm-style div:nth-child(2) div:nth-child(2) div div:nth-child(3) div{
  position: relative;
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border-radius: 100%;
}
.gm-style div:nth-child(2) div:nth-child(1) div:nth-child(2) div:nth-child(1) {
  position: absolute;
  left: 0px;
  top: -7px !important;
  z-index: 30;
}
.new {
  position: relative;
}
.CPU-p-tag-fleetpage {
  position: absolute;
  bottom: 4px;
  left: 5px;
  color: #FF6767;
  font-size: 13px;
  font-weight: 700;
}